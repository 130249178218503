@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .tw-no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .tw-no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "./CommonStyles/animations.scss";
@import "./CommonStyles/helper.scss";
@import "./CommonStyles/mixin.scss";
@import "./ds-colors.scss";
@import "./ds-font.scss";
@import "./Typography.scss";
@import "./margin.scss";
@import "./padding.scss";
// @import "./Assets/fonts/Sf_Pro/FontsFree-Net-SF-Pro-Rounded-Bold.ttf";

@font-face {
  font-family: "Sf-Pro-Rounded-Light";
  src:
    local("SF-Pro-Rounded-Light.otf"),
    url("./Assets/fonts/Sf_Pro/SF-Pro-Rounded-Light.otf") format("truetype");
}

@font-face {
  font-family: "Sf-Pro-Rounded-Medium";
  src:
    local("SF-Pro-Rounded-Medium.otf"),
    url("./Assets/fonts/Sf_Pro/SF-Pro-Rounded-Medium.otf") format("truetype");
}

:root {
  --fontFamliy-jakarta: "Plus Jakarta Sans";
  --fontFamily-Manrope: "Manrope";
  --fontFamily-Inter: "Inter";
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  background: #fff;
}

.btn-theme {
  background-color: #46a414;
  color: #fff;
}

.heading-top-fixed {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 240px);
  z-index: 111;
  height: 86px;
}

.box-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001c;
  border-radius: 8px;
}

.cp {
  cursor: pointer;
}

.cn {
  cursor: no-drop;
}

.primary-cta {
  background-color: #000000;
  color: #ffffff;
  border: #000000;
  font-weight: 600;
}

.secondary-cta {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #000000 !important;
}

.disabled-cta {
  border: none;
  background-color: #00000029;
  color: #ffff;
  font-weight: 600;
}

button {
  cursor: pointer;
}

.br38 {
  border-radius: 38px;
}

.br32 {
  border-radius: 32px;
}

.op5 {
  opacity: 0.5;
}

.op4 {
  opacity: 0.4;
}

.op3 {
  opacity: 0.3;
}

.pointer-none {
  pointer-events: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.component-center {
  margin: 115px auto;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

/* Border radius */
.br-30 {
  border-radius: 30px !important;
}

.br-24 {
  border-radius: 24px;
}

.br-36 {
  border-radius: 36px;
}

.br-none {
  border: none;
}

.br-4 {
  border-radius: 4px;
}

.br-23 {
  border-radius: 23px;
}

.br-10 {
  border-radius: 10px;
}

.br-12 {
  border-radius: 12px;
}

.br-16 {
  border-radius: 16px;
}

.br-30 {
  border-radius: 30px;
}

.br-40 {
  border-radius: 40px;
}

.br-8 {
  border-radius: 8px;
}

.br-4 {
  border-radius: 4px;
}

.break-word {
  word-break: break-word;
}

.dn {
  display: none;
}

.ds-pre {
  white-space: pre-line;
}

.grid {
  display: grid;
}

.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap16 {
  gap: 16px;
}

.gap32 {
  gap: 32px;
}

.gap6 {
  gap: 6px;
}

.gap20 {
  gap: 20px;
}

.hgap20 {
  gap: 0px 20px;
}

.hgap24 {
  gap: 0px 24px;
}

.grid-template-columns-50 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-template-columns-80 {
  grid-template-columns: 1fr 6fr;
}

.invert {
  filter: invert(1);
}

.white-img {
  filter: brightness(0) invert(1);
}

.w-auto {
  width: auto;
}

.hide-scroll-view::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
}

#slider {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.slide-in {
  animation: slide-in 1s forwards;
  -webkit-animation: slide-in 1s forwards;
}

.slide-up {
  animation: slide-up 0.3s;
}

@keyframes slide-up {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes loader-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

.btn-reset {
  // background-color: transparent;
  border: none;
  padding: 0;
}

.fs12 {
  font-size: 12px;
}

.fs14 {
  font-size: 14px;
}

.obj-fit-contain {
  object-fit: contain;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  // overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

.mt-auto {
  margin-top: auto;
}

.w-fit {
  width: fit-content;
}

.sticky {
  position: sticky;
}

.cursor-not-allowed-important {
  cursor: not-allowed !important;
}

.w-mc {
  width: max-content;
}

.w-fc {
  width: fit-content;
}

.aspect-ratio--5x3 {
  aspect-ratio: 5/3;
}
